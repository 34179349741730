import React, { useState, useRef } from 'react'
import Layout from '../layout'
import { ThemeProvider, makeStyles } from '@material-ui/styles'
import ACC_THEME from '../../themes'
import renderBloks from '@renderBloks'
import {
  Box,
  CssBaseline,
  Input,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
} from '@material-ui/core'
import { H3, H5, Img, TextSm, PageContainer, ContentContainer } from '@system'
import { imgMaxWidths } from '@variables/global-variables'
import get from 'lodash/get'

const useStyles = makeStyles({
  headerImage: {
    width: '100%',
    height: '66vh',
    objectPosition: 'top',
    maxHeight: '600px',
  },
  body: {
    color: ACC_THEME.palette.text.tertiary,
    marginTop: '-300px',
    position: 'relative',
    background: ACC_THEME.palette.background.default,
    width: '100%',
    margin: '0 auto',
    padding: '0 65.6px',
    paddingTop: '116px',
    paddingBottom: '25px',
    textAlign: 'center',
    [ACC_THEME.breakpoints.between('md', 'lg')]: {
      paddingLeft: '49.6px',
      paddingRight: '49.6px',
    },
    [ACC_THEME.breakpoints.down('md')]: {
      paddingLeft: '41.6px',
      paddingRight: '41.6px',
    },
    [ACC_THEME.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  title: {
    color: ACC_THEME.palette.primary.main,
  },
  partnerCards: {
    height: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '50px',
  },
  filtersMenu: {
    display: 'block',
    background: ACC_THEME.palette.background.paper,
    margin: '40px 0px',
    padding: '4px',
    [ACC_THEME.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  filters: {
    maxWidth: '98%',
    margin: '0px auto 8px',
    padding: '4px 8px',
    [ACC_THEME.breakpoints.up('sm')]: {
      margin: 'auto',
    },
    [ACC_THEME.breakpoints.up('md')]: {
      maxWidth: '100%',
      margin: 'auto 0px',
    },
    [ACC_THEME.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  flexBox: {
    padding: '4px 8px',
    [ACC_THEME.breakpoints.down('xs')]: {
      maxWidth: '100% !important',
      flexBasis: '100% !important',
    },
  },
  carousel: {
    paddingBottom: '60px',
  },
  textField: {
    width: '100%',
    '& input': {
      padding: '7px 10px',
      height: '32px',
      boxSizing: 'border-box',
      backgroundColor: ACC_THEME.palette.common.white,
      color: ACC_THEME.palette.text.font,
      fontSize: '0.831rem',
    },
  },
  selectInput: {
    height: '32px',
    backgroundColor: ACC_THEME.palette.common.white,
    '& fieldset': {
      border: 'none',
    },
    '&>div': {
      fontSize: '11px',
      paddingTop: '4px',
      paddingBottom: '4px',
      '&:focus': {
        backgroundColor: ACC_THEME.palette.common.white,
      },
    },
  },
  customSelect: {
    '& label': {
      color: ACC_THEME.palette.background.slate,
      fontSize: ACC_THEME.typography.body2.fontSize,
      zIndex: 1,
      transform: 'translateY(10px)',
      transformOrigin: 'left',
      paddingLeft: '14px',
      '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
        color: ACC_THEME.palette.background.slate,
        transform: 'translateY(10px) !important',
      },
    },
    '& svg': {
      fontSize: '1.5rem',
      color: ACC_THEME.palette.background.slate,
    },
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
        borderColor: ACC_THEME.palette.secondary.main,
      },
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: ACC_THEME.palette.error.main,
    },
    '& .MuiFormLabel-filled': {
      display: 'none',
    },
    [ACC_THEME.breakpoints.down('sm')]: {
      width: '100%',
    },
    [ACC_THEME.breakpoints.up('sm')]: {
      width: '100%',
    },
  },
  description: {
    padding: '25px 20px',
  },
})

const ChannelPartnersIndex = (props) => {
  const { metaData, header, title, description } = props.blok

  const channelPartners = useRef()

  const classes = useStyles()
  const headerImage = get(header, '[0].image')

  const partnerCards = props.blok.partnerCards.sort((a, b) =>
    a.companyName.localeCompare(b.companyName)
  )

  const [selectedRegion, setSelectedRegion] = useState('')
  const [searchValue, setSearchValue] = useState(null)

  const handleFiltersChange = (event) => {
    setSelectedRegion(event.target.value)
  }

  const onChangeSearchText = (event) => {
    setSearchValue(null)
    setSearchValue(event.target.value)
  }

  const regionFilteredCards =
    partnerCards &&
    partnerCards.filter((card) =>
      selectedRegion ? card.country === selectedRegion : true
    )

  const filteredChannelPartnerCards = regionFilteredCards.filter((card) =>
    searchValue
      ? card.companyName.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
      : true
  )

  const regionOptions =
    partnerCards &&
    partnerCards
      .reduce(
        (acc, curr) =>
          acc.indexOf(curr.country) < 0 ? [...acc, curr.country] : acc,
        []
      )
      .sort()

  return (
    <Layout metaData={metaData}>
      <ThemeProvider theme={ACC_THEME}>
        <CssBaseline />
        <Box ref={channelPartners}>
          {headerImage && (
            <Img
              className={classes.headerImage}
              maxWidth={imgMaxWidths.headerImage}
              src={headerImage}
              style={{ objectFit: 'cover' }}
            />
          )}
          <PageContainer>
            <Box className={classes.body}>
              {title && <H3 className={classes.title}>{title}</H3>}
              {description && (
                <H5 className={classes.description}>{description}</H5>
              )}
            </Box>
            <Box>
              <ContentContainer>
                <Box className={classes.filtersMenu}>
                  <Box
                    container
                    spacing={2}
                    className={classes.filters}
                    display="flex"
                    flex="1"
                  >
                    <Box className={classes.flexBox} display="flex" flex="2">
                      <FormControl
                        variant="outlined"
                        className={classes.customSelect}
                      >
                        <InputLabel htmlFor={'Region'}>
                          <TextSm>Region</TextSm>
                        </InputLabel>
                        <Select
                          className={classes.selectInput}
                          defaultValue=""
                          id={'Region'}
                          onChange={handleFiltersChange}
                        >
                          <MenuItem value="">
                            <TextSm>None</TextSm>
                          </MenuItem>
                          {regionOptions &&
                            regionOptions.map((option) => (
                              <MenuItem key={option} value={option}>
                                <TextSm>{option}</TextSm>
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Box>
                    <Box
                      className={classes.flexBox}
                      xs={12}
                      sm={8}
                      display="flex"
                      flex="2"
                    >
                      <Input
                        placeholder="Search"
                        disableUnderline={true}
                        className={classes.textField}
                        onChange={onChangeSearchText}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box className={classes.partnerCards}>
                  {renderBloks(
                    !!selectedRegion || !!searchValue
                      ? filteredChannelPartnerCards
                      : partnerCards
                  )}
                </Box>
              </ContentContainer>
            </Box>
          </PageContainer>
        </Box>
      </ThemeProvider>
    </Layout>
  )
}

export default ChannelPartnersIndex
